// @ts-nocheck
import './App.css';
import React, { useState, useEffect } from 'react';
import {
  BrowserRouter,
  createBrowserRouter,
  Outlet,
  RouterProvider,
  useMatches,
} from 'react-router-dom';

import ErrorPage from './pages/ErrorPage';
import UnauthorizedPage from './pages/UnauthorizedPage';
import Screen from './pages/Screen';
import { MsalProvider } from '@azure/msal-react';

import SideNavbar from './components/SideNavbar';
import AzureAuthInitialize from './pages/AzureAuthInitialize';
import {
  AZURE_AD_CLIENT_ID,
  AZURE_AUTH_PATH,
  ROOT_PATH,
  ERROR_PATH,
  SCREEN_PATH,
  SCREEN_SID_PATH,
  SCREEN_SID_SCREEN_PATH,
  SCREEN_SID_UNIQUE_SCREEN_PATH,
  UNAUTHORIZED_PATH,
} from './utils/constants';
import { ChangePasswordPopup } from './components/profile/Profile';
import SessionTimeout from './components/timeout/SessionTimeout';
import AnnouncementWrapper from './components/AnnouncementWrapper';
import Login from './pages/Login';
import { AppProvider, useAppContext } from './provider/AppProvider';
import { getCookie } from './utils/storageHelpers';
import NavBar from './components/NavBar';
import DashboardScreen from './pages/DashboardScreen';

const AppShell = function () {
  const [changePassword, setChangePassword] = useState(true);
  const { userProfile } = useAppContext();
  const { params } = useMatches()[0];
  console.log(userProfile);
  return (
    <div className="flex h-full bg-gray-100">
      <div className="flex flex-col">
        <SideNavbar />
      </div>
      <div className="flex flex-col h-full w-full overflow-hidden">
        <NavBar params={params} />
        <Outlet />
        {userProfile?.isPasswordReset && (
          <ChangePasswordPopup
            changePassword={changePassword}
            setChangePassword={setChangePassword}
            showCancelButton={false}
            onShow
          />
        )}
        <SessionTimeout />
      </div>
    </div>
  );
};

export const router = createBrowserRouter([
  {
    path: ROOT_PATH,
    element: <AppShell />,
    errorElement: <ErrorPage />,
    children: [
      {
        index: true,
        element: (
          <AnnouncementWrapper>
            <DashboardScreen />
          </AnnouncementWrapper>
        ),
      },
      {
        path: UNAUTHORIZED_PATH, // Edit /view
        element: <UnauthorizedPage />,
      },
      {
        path: ERROR_PATH, // Edit /view
        element: <ErrorPage />,
      },
      {
        path: AZURE_AUTH_PATH, // Edit /view
        element: <AzureAuthInitialize />,
      },
      {
        path: SCREEN_PATH,
        element: <Screen />,
        errorElement: <ErrorPage />,
      },
      {
        path: SCREEN_SID_PATH, // List
        element: <Screen />,
        errorElement: <ErrorPage />,
      },
      {
        path: SCREEN_SID_SCREEN_PATH, // Create
        element: <Screen />,
        errorElement: <ErrorPage />,
      },
      {
        path: SCREEN_SID_UNIQUE_SCREEN_PATH, // Edit /view
        element: <Screen />,
        errorElement: <ErrorPage />,
      },
    ],
  },
]);

export const loginRouter = createBrowserRouter([
  {
    path: '/',
    element: <Login />,
  },
]);

function App({ msalInstance }) {
  const [authenticated, setAuthenticated] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (getCookie('access_token')) {
      setAuthenticated(true);
      setLoading(false);
    } else {
      setAuthenticated(false)
      setLoading(false);
    }
  });

  if (loading) {
    return <h1>Loading...</h1>;
  }
  const appElement = (
    <AppProvider>
      <RouterProvider router={authenticated ? router : loginRouter} />{' '}
    </AppProvider>
  );
  if (AZURE_AD_CLIENT_ID && AZURE_AD_CLIENT_ID.length) {
    return <MsalProvider instance={msalInstance}>{appElement}</MsalProvider>;
  }
  return appElement;
}

export default App;

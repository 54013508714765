import { Fragment, useEffect, useState, useMemo, useRef, useCallback } from 'react';
import { Dialog, Disclosure, Menu, Transition } from '@headlessui/react';
import {
  ArrowRightOnRectangleIcon,
  Bars3Icon,
  BellIcon,
  CheckBadgeIcon,
  DocumentMagnifyingGlassIcon,
  HandRaisedIcon,
  InboxArrowDownIcon,
  MapIcon,
  PlayCircleIcon,
  ShieldExclamationIcon,
  ShoppingCartIcon,
  WrenchScrewdriverIcon,
  XMarkIcon,
} from '@heroicons/react/24/outline';
import { eraseCookie } from '../utils/storageHelpers';
import { ROOT_PATH, LOGIN_UI, LOGO_URL, REGION_GATEWAY } from '../utils/constants';
import { Link, useNavigate } from 'react-router-dom';
import Icons from './SideNavbarIcons';
import { H3 } from './twEssential';
import '../App.css';
import { sendRequest } from '../utils/apiCall';
import LOCAL_LOGO from '../logo/regere_logo.png';
import { QuestionMarkCircleIcon } from '@heroicons/react/24/outline';
import { HelpCenter } from './HelpCenter';
import { ShouldRenderComponent } from './ShouldRenderComponent';
import { setTour, setTourConfig } from '../features/screen/screenSlice';
import { useDispatch } from 'react-redux';
import { getProfileFromLocalStorage, getTime } from '../utils/helperFunctions';
import { EmptyList } from './ListTable';
import { useProfileIconName } from '../hooks/userData';
import { useProfileData } from '../hooks/useProfileData';
import { useSideNav } from '../hooks/useSideNav';
import { useAppContext } from '../provider/AppProvider';
import { useNotifications } from '../hooks/useNotifications';
function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

export default function NavBar({ params, docs, tours }) {
  let result;
  const [menu, setMenu] = useState([]);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { getSideNav } = useSideNav();
  const currentProfile = getProfileFromLocalStorage();
  const profileIconName = useProfileIconName();
  const [showHelpCenterDialog, setShowHelpCenterDialog] = useState(false);
  const [showNotifications, setShowNotifications] = useState(false);
  const {
    userProfile,
    setUserProfile,
    notify,
    notifications,
    setNotifications,
    unopenedNotificationCount,
    setUnopenedNotificationCount,
  } = useAppContext();
  const [numCardsToShow, setNumCardsToShow] = useState(5);
  const showAllNotifications = numCardsToShow >= notifications?.length;
  const notificationRef = useRef(null);
  const bellIconRef = useRef(null);
  const { getUserProfile } = useProfileData();
  const { fetchNotifications } = useNotifications();
  const handleShowMore = () => {
    window.location.replace('/coreV2/notification/list');
  };

  const handleNotificationClick = async (message, data, navigatePath, subject) => {
    if (data) {
      try {
        const { response, responseError } = await sendRequest({
          url: `${REGION_GATEWAY}/coreV2/instances/notification?data=${data}&&notifyUserId=${currentProfile._id}`,
          method: 'GET',
        });
        if (response && response.status === 200) {
          let _id = response.data.data.instances[0]._id;
          try {
            let { response } = await sendRequest({
              url: `${REGION_GATEWAY}/coreV2/instances/notification/${_id}`,
              method: 'PUT',
              body: {
                data: {
                  isRead: true,
                },
              },
            });
            if (response.status === 200) {
              result = response?.data?.data;
              setNotifications(response?.data?.data);
            }
          } catch (error) {
            console.log(error);
          }
        }
      } catch (error) {
        console.error(error);
      }
      let notificationList = [...notifications];
      let index = notificationList.findIndex((e) => e.id === data);
      notificationList[index].isRead = true;
      setNotifications(notificationList);
      let unOpenedCount = notificationList?.filter((e) => !e.isRead)?.length;
      setUnopenedNotificationCount(unOpenedCount);
      if (subject !== 'Job Completed') window.location.replace(navigatePath);
    }
  };

  const moduleIcon = (id, path, subject) => {
    if (path.startsWith('/coreV2/ticket-quality_ticket')) {
      return (
        <div
          className="mx-auto flex h-11 w-11 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10"
          style={{ backgroundColor: '#FAE8FF' }}
        >
          <ShieldExclamationIcon className="h-5 w-5 text-black-500" aria-hidden="true" />
        </div>
      );
    } else if (path.startsWith('/coreV2/ticket-machine_breakdown_ticket')) {
      return (
        <div
          className="mx-auto flex h-11 w-11 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10"
          style={{ backgroundColor: '#FFE1E0' }}
        >
          <WrenchScrewdriverIcon className="h-5 w-5 text-black-500" aria-hidden="true" />
        </div>
      );
    } else if (path.startsWith('/coreV2/ticket-call_supervisor_ticket')) {
      return (
        <div
          className="mx-auto flex h-11 w-11 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10"
          style={{ backgroundColor: '#E0F2FE' }}
        >
          <HandRaisedIcon className="h-5 w-5 text-black-500" aria-hidden="true" />
        </div>
      );
    } else if (path.startsWith('/coreV2/ticket-pending_item_material')) {
      return (
        <div
          className="mx-auto flex h-11 w-11 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10"
          style={{ backgroundColor: '#FEF3C7' }}
        >
          <ShoppingCartIcon className="h-5 w-5 text-black-500" aria-hidden="true" />
        </div>
      );
    } else if (path.startsWith('/checklist-service/checklist_instances-all/')) {
      return (
        <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-green-100 sm:mx-0 sm:h-10 sm:w-10">
          <DocumentMagnifyingGlassIcon className="h-5 w-5 text-black-500" aria-hidden="true" />
        </div>
      );
    } else if (path.startsWith('/coreV2/ticket-checklist_ticket')) {
      return (
        <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-yellow-100 sm:mx-0 sm:h-10 sm:w-10">
          <DocumentMagnifyingGlassIcon className="h-5 w-5 text-black-500" aria-hidden="true" />
        </div>
      );
    } else if (subject == 'Job Completed') {
      return (
        <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-green-100 sm:mx-0 sm:h-10 sm:w-10">
          <CheckBadgeIcon className="h-5 w-5 text-black-500" aria-hidden="true" />
        </div>
      );
    } else if (path.startsWith('/coreV2/ticket-pending_item_process')) {
      return (
        <div
          className="mx-auto flex h-11 w-11 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10"
          style={{ backgroundColor: '#F2F9FE' }}
        >
          <InboxArrowDownIcon className="h-5 w-5 text-black-500" aria-hidden="true" />
        </div>
      );
    } else if (path.startsWith('/coreV2/ticket-material_shortage')) {
      return (
        <div
          className="mx-auto flex h-11 w-11 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10"
          style={{ backgroundColor: '#FEF3C7' }}
        >
          <ShoppingCartIcon className="h-5 w-5 text-black-500" aria-hidden="true" />
        </div>
      );
    }
  };
  useEffect(() => {
    (async () => {
      setUserProfile(await getUserProfile());
      setNotifications(await fetchNotifications());
      await fetchUnopenedNotificationCount();
    })();
  }, [notify]);

  useEffect(() => {
    (async () => {
      await getUserProfile();
      setNotifications(await fetchNotifications());
      await fetchUnopenedNotificationCount();
    })();
  }, []);

  const handleShowNotifications = () => {
    setShowNotifications(!showNotifications);
  };

  const handleClickOutside = (event) => {
    if (
      !notificationRef.current?.contains(event.target) &&
      !bellIconRef.current?.contains(event.target)
    ) {
      setShowNotifications(false);
    }
  };
  useEffect(() => {
    const handleClick = (event) => {
      handleClickOutside(event);
    };
    document.addEventListener('click', handleClick);
    return () => {
      document.removeEventListener('click', handleClick);
    };
  }, []);

  let logo = LOGO_URL ? LOGO_URL : LOCAL_LOGO;

  const userLogout = () => {
    eraseCookie('access_token');
    eraseCookie('holiFlag');
    localStorage.clear();
    window.location.replace(`${LOGIN_UI}`);
  };

  const [isOpen, setIsOpen] = useState(false);

  const handleToggleSidebar = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    (async () => {
      setMenu(await getSideNav());
    })();
  }, []);

  // const startTour = async (tourId) => {
  //   try {
  //     dispatch(setTourConfig({ steps: [] }));
  //     let { response } = await sendRequest({
  //       url: `${REGION_GATEWAY}/coreV2/instances/tour/${tourId}`,
  //       method: 'GET',
  //     });
  //     if (response.status === 200) {
  //       const tours = response.data.data;
  //       dispatch(setTourConfig(tours.stepConfiguration));
  //       dispatch(setTour(true));
  //     }
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  const fetchUnopenedNotificationCount = useCallback(async () => {
    try {
      if (currentProfile._id) {
        const { response, responseError } = await sendRequest({
          url: `${REGION_GATEWAY}/coreV2/instances/notification?isRead=false&&notifyUserId=${currentProfile._id}`,
          method: 'GET',
        });
        if (response && response.status === 200) {
          const count = response.data.data.instances.length || 0;
          setUnopenedNotificationCount(count);
        }
      }
    } catch (error) {
      console.error(error);
    }
  }, [currentProfile._id]);

  const markAllReadonClicks = async (data: any) => {
    if (data) {
      try {
        const { response, responseError } = await sendRequest({
          url: `${REGION_GATEWAY}/coreV2/instances/notification?data=${data}&&notifyUserId=${currentProfile._id}`,
          method: 'GET',
        });
        if (response && response.status === 200) {
          let _id = response.data.data.instances[0]._id;
          try {
            let { response } = await sendRequest({
              url: `${REGION_GATEWAY}/coreV2/instances/notification/${_id}`,
              method: 'PUT',
              body: {
                data: {
                  isRead: true,
                },
              },
            });
            if (response.status === 200) {
              setNotifications(await fetchNotifications());
            }
          } catch (error) {
            console.log(error);
          }
        }
      } catch (error) {
        console.error(error);
      }
    }
  };

  const markAllAsRead = () => {
    const unreadNotifications = notifications.filter((item) => !item.isRead);

    unreadNotifications.forEach((item) => {
      markAllReadonClicks(item?.id);
    });
    setUnopenedNotificationCount(0);
  };

  return (
    <div className="grid gap-1 grid-cols-1 col-span-1">
      <Disclosure as="nav" className="bg-gray-50 shadow-md">
        {({ open }) => (
          <>
            <div className="mx-auto px-4 sm:px-6">
              <div className="flex h-12 justify-between items-center">
                <Link to={ROOT_PATH} className="hidden md:block items-start -mt-2">
                  <div className="flex pt-2 items-center justify-center">
                    <img style={{ height: '30px' }} src={logo} alt="" />
                  </div>
                </Link>
                <div className="block md:hidden">
                  <button onClick={handleToggleSidebar}>
                    <Bars3Icon
                      className="w-7 text-indigo-500 mt-1 font-semibold"
                      data-bs-toggle="collapse"
                      role="button"
                      aria-expanded="false"
                      aria-controls="collapseWithScrollbar"
                    />
                  </button>

                  {/* Sidebar */}
                  <Transition.Root show={isOpen} as={Fragment}>
                    <div className="fixed inset-0 overflow-hidden z-50">
                      <Transition.Child
                        as={Fragment}
                        enter="transition-opacity ease-linear duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="transition-opacity ease-linear duration-300"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                      >
                        <div
                          className={`fixed inset-0 bg-black ${
                            isOpen
                              ? 'opacity-50 pointer-events-auto'
                              : 'opacity-0 pointer-events-none'
                          }`}
                          onClick={handleToggleSidebar}
                        />
                      </Transition.Child>
                      <Transition.Child
                        as={Fragment}
                        enter="transition-transform ease-in-out duration-300"
                        enterFrom="-translate-x-full"
                        enterTo="translate-x-0"
                        leave="transition-transform ease-in-out duration-300"
                        leaveFrom="translate-x-0"
                        leaveTo="-translate-x-full"
                      >
                        <div className="fixed inset-y-0 left-0 w-[80%] bg-white z-50 flex flex-col justify-between">
                          {/* Sidebar content */}
                          <div>
                            <div className="flex justify-between items-center p-4 ">
                              <Link to={ROOT_PATH}>
                                <div>
                                  <img style={{ height: '30px' }} src={logo} alt="" />
                                </div>
                              </Link>
                              <button onClick={handleToggleSidebar}>
                                <XMarkIcon className="w-6 h-6 text-indigo-600 font-semibold" />
                              </button>
                            </div>
                            <hr className="w-full" />
                            <div className="overflow-x-hidden overflow-y-scroll h-[70vh] scrollStyle">
                              {menu &&
                                menu?.length > 0 &&
                                menu?.map((item) => {
                                  const ItemIcon = Icons[item.icon];
                                  return !item.children ? (
                                    <ShouldRenderComponent
                                      visibilityCondition={item.visibility?.conditions}
                                    >
                                      <div key={item.name} className="mx-2 pb-1">
                                        <a
                                          href={item.href}
                                          // onClick={() =>}
                                          className={`mx-2 pb-1 bg-gray-100`}
                                          target={item.target}
                                        >
                                          <ItemIcon
                                            key={item.name}
                                            className={classNames(
                                              item.current
                                                ? 'text-gray-50'
                                                : 'text-gray-500 group-hover:text-gray-500',
                                              'mr-3 h-6 w-6 flex-shrink-0',
                                            )}
                                            aria-hidden="true"
                                          />
                                          <span className="flex-1 pl-1">{item.name}</span>
                                        </a>
                                      </div>
                                    </ShouldRenderComponent>
                                  ) : (
                                    <ShouldRenderComponent
                                      visibilityCondition={item.visibility?.conditions}
                                    >
                                      <Disclosure
                                        as="div"
                                        key={item.name}
                                        className={`space-y-1 pb-1 mx-2 bg-gray-100`}
                                      >
                                        {({ open }) => (
                                          <>
                                            <Disclosure.Button
                                              className={
                                                'text-gray-500 bg-white hover:text-gray-600 hover:bg-indigo-50 group flex w-full items-center rounded-md py-2 pl-2 text-left text-sm font-medium focus:outline-none focus:ring-2 focus:ring-indigo-500'
                                              }
                                            >
                                              <ItemIcon
                                                className="mr-3 h-6 w-8 flex-shrink-0 text-gray-500 group-hover:text-gray-500 items-center"
                                                aria-hidden="true"
                                              />
                                              <span className="flex-1">{item.name}</span>
                                              <svg
                                                className={classNames(
                                                  open
                                                    ? 'rotate-90 text-gray-400 hover:text-gray-500'
                                                    : 'text-gray-400 hover:text-gray-500',
                                                  'ml-3 h-5 w-5 flex-shrink-0 transform transition-colors duration-150 ease-in-out group-hover:text-gray-500 mr-5',
                                                )}
                                                viewBox="0 0 20 20"
                                                aria-hidden="true"
                                              >
                                                <path d="M6 6L14 10L6 14V6Z" fill="currentColor" />
                                              </svg>
                                            </Disclosure.Button>
                                            <Disclosure.Panel className="space-y-1">
                                              {item.children.map((subItem) => (
                                                <ShouldRenderComponent
                                                  visibilityCondition={
                                                    subItem.visibility?.conditions
                                                  }
                                                >
                                                  <Disclosure.Button
                                                    key={subItem.name}
                                                    as="a"
                                                    href={subItem.href}
                                                    className="text-gray-500 bg-white hover:text-gray-600 hover:bg-indigo-50 group flex w-full items-center py-2 pl-2 ml-1 text-left text-sm font-medium focus:outline-none focus:ring-2 focus:ring-indigo-500"
                                                  >
                                                    <div className="flex pl-1.5 items-center">
                                                      <span className="ml-10">{subItem.name}</span>
                                                    </div>
                                                  </Disclosure.Button>
                                                </ShouldRenderComponent>
                                              ))}
                                            </Disclosure.Panel>
                                          </>
                                        )}
                                      </Disclosure>
                                    </ShouldRenderComponent>
                                  );
                                })}
                            </div>
                          </div>
                          <div className="bg-slate-50 flex justify-between items-center">
                            <div
                              className="flex gap-4 p-3 items-center cursor-pointer"
                              onClick={() => {
                                navigate(`/coreV2/user/profile`);
                              }}
                            >
                              <span className="inline-flex h-8 w-8 items-center justify-center rounded-full bg-indigo-500">
                                <span className="text-md  leading-none text-white">
                                  {profileIconName}
                                </span>
                              </span>
                              <H3>{currentProfile?.firstName}</H3>
                            </div>
                            <div>
                              <button
                                onClick={userLogout}
                                className="text-gray-500 hover:text-gray-600 hover:bg-indigo-50 group flex items-center py-2 pl-3 text-left text-sm font-medium focus:outline-none focus:ring-2 focus:ring-indigo-500 p-3"
                              >
                                <ArrowRightOnRectangleIcon className="h-6 w-6" />
                              </button>
                            </div>
                          </div>
                        </div>
                      </Transition.Child>
                    </div>
                  </Transition.Root>
                </div>
                <div>
                  {/* <BreadCrumb
                                    params={params}
                                /> */}
                </div>
                <div className="flex items-center">
                  <div className="md:ml-4 md:flex md:flex-shrink-0 md:items-center">
                    {/* TODO: TOUR WIP
                    {tours && tours.length ? (
                      <Menu as="div" className="hidden md:block relative ml-3">
                        <div>
                          <Menu.Button className="flex rounded-full bg-white text-sm focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
                            <button
                              type="button"
                              className="rounded-full bg-white p-1 mr-2 text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                            >
                              <span className="sr-only">Tour</span>
                              <MapIcon className="h-6 w-6" aria-hidden="true" />
                            </button>
                          </Menu.Button>
                        </div>
                        <Transition
                          as={Fragment}
                          enter="transition ease-out duration-200"
                          enterFrom="transform opacity-0 scale-95"
                          enterTo="transform opacity-100 scale-100"
                          leave="transition ease-in duration-75"
                          leaveFrom="transform opacity-100 scale-100"
                          leaveTo="transform opacity-0 scale-95"
                        >
                          <Menu.Items className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                            {tours.map((_tour, _tIndex) => {
                              return (
                                <Menu.Item key={_tIndex}>
                                  {({ active }) => (
                                    <button
                                      type="button"
                                      className={classNames(
                                        active ? 'bg-gray-100' : '',
                                        'text-gray-700 inline-flex items-center gap-x-1.5 rounded-md  px-3 py-2 text-sm font-semibold w-full shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2',
                                      )}
                                      onClick={() => {
                                        startTour(_tour._id);
                                      }}
                                    >
                                      <PlayCircleIcon className="h-6 w-6" aria-hidden="true" />
                                      {_tour.name}
                                    </button>
                                  )}
                                </Menu.Item>
                              );
                            })}
                          </Menu.Items>
                        </Transition>
                      </Menu>
                    ) : null} */}
                    <button
                      type="button"
                      className="rounded-full bg-white p-1 mr-2 text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                      onClick={() => {
                        setShowHelpCenterDialog(!showHelpCenterDialog);
                      }}
                    >
                      <span className="sr-only">Get Help</span>
                      <QuestionMarkCircleIcon className="h-6 w-6" aria-hidden="true" />
                    </button>
                    <Transition.Root show={showHelpCenterDialog} as={Fragment}>
                      <Dialog as="div" className="relative z-10" onClose={setShowHelpCenterDialog}>
                        <div className="fixed inset-0" />

                        <div className="fixed inset-0 overflow-hidden">
                          <div className="absolute inset-0 overflow-hidden">
                            <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 top-12">
                              <Transition.Child
                                as={Fragment}
                                enter="transform transition ease-in-out duration-500 sm:duration-700"
                                enterFrom="translate-x-full"
                                enterTo="translate-x-0"
                                leave="transform transition ease-in-out duration-500 sm:duration-700"
                                leaveFrom="translate-x-0"
                                leaveTo="translate-x-full"
                              >
                                <Dialog.Panel className="pointer-events-auto w-screen max-w-md">
                                  <div className="flex h-full flex-col overflow-y-scroll bg-white py-6 shadow-xl">
                                    <div className="px-4 sm:px-6">
                                      <div className="flex items-start justify-between">
                                        <Dialog.Title className="text-base font-semibold leading-6 text-gray-900">
                                          Help Center
                                        </Dialog.Title>
                                        <div className="ml-3 flex h-7 items-center">
                                          <button
                                            type="button"
                                            className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                                            onClick={() => setShowHelpCenterDialog(false)}
                                          >
                                            <span className="sr-only">Close panel</span>
                                            <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                                          </button>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="relative mt-6 flex-1 px-4 sm:px-6">
                                      {/* <HelpCenter docs={docs} /> */}
                                    </div>
                                  </div>
                                </Dialog.Panel>
                              </Transition.Child>
                            </div>
                          </div>
                        </div>
                      </Dialog>
                    </Transition.Root>
                    <button
                      ref={bellIconRef}
                      type="button"
                      className={`relative rounded-full bg-white p-1 text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 ${
                        unopenedNotificationCount > 0 ? 'has-unopened' : ''
                      }`}
                      onClick={handleShowNotifications}
                    >
                      {unopenedNotificationCount > 0 && (
                        <div className="absolute right-0 bottom-5 inline-flex items-center justify-center px-1.5 py-0.5 text-xs font-semibold leading-none text-red-100 bg-red-600 rounded-full">
                          <span>
                            {unopenedNotificationCount > 99 ? '99+' : unopenedNotificationCount}
                          </span>
                        </div>
                      )}
                      <BellIcon className="h-6 w-6" aria-hidden="true" />
                    </button>
                    {showNotifications && (
                      <div
                        className={`flex flex-col absolute top-14 right-4 w-96 ${
                          showNotifications ? 'h-4/5 overflow-y-scroll' : 'h-fit'
                        } bg-white rounded-lg shadow-lg z-20 pt-2 p-4`}
                      >
                        <div className="flex justify-between p-3">
                          <div className="text-lg font-semibold flex content-center">
                            <h3>Notifications</h3>
                          </div>
                          <div className="flex content-center">
                            <button
                              className="text-gray-400 text-sm mark-read-btn"
                              onClick={(e) => {
                                e.stopPropagation();
                                markAllAsRead();
                              }}
                            >
                              <span className="text-gray-500 flex items-center hover:text-indigo-600">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  fill="none"
                                  viewBox="0 0 24 24"
                                  stroke-width="1.5"
                                  stroke="currentColor"
                                  className="size-4 mr-1.5"
                                >
                                  <path
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    d="M21.75 9v.906a2.25 2.25 0 0 1-1.183 1.981l-6.478 3.488M2.25 9v.906a2.25 2.25 0 0 0 1.183 1.981l6.478 3.488m8.839 2.51-4.66-2.51m0 0-1.023-.55a2.25 2.25 0 0 0-2.134 0l-1.022.55m0 0-4.661 2.51m16.5 1.615a2.25 2.25 0 0 1-2.25 2.25h-15a2.25 2.25 0 0 1-2.25-2.25V8.844a2.25 2.25 0 0 1 1.183-1.981l7.5-4.039a2.25 2.25 0 0 1 2.134 0l7.5 4.039a2.25 2.25 0 0 1 1.183 1.98V19.5Z"
                                  />
                                </svg>
                                Mark all as read
                              </span>
                            </button>
                          </div>
                        </div>
                        <div className="flex-1 h-5/6 ">
                          {notifications?.length === 0 ? (
                            <div className="flex justify-center">
                              <EmptyList name="notification" showSubText={false} />
                            </div>
                          ) : (
                            <>
                              {notifications?.slice(0, numCardsToShow).map((notification) => (
                                <a
                                  href="#"
                                  onClick={() =>
                                    handleNotificationClick(
                                      notification?.message,
                                      notification?.id,
                                      notification?.navigatePath,
                                      notification?.subject,
                                      notification?.createdBy,
                                      notification?.createdAt,
                                    )
                                  }
                                >
                                  <div
                                    key={notification?.id}
                                    className={`relative p-1 mb-1 border rounded-lg ${
                                      notification?.isRead === true ? 'bg-slate-50' : 'bg-white'
                                    }`}
                                  >
                                    <div className="flex items-center justify-start mb-0.5">
                                      {moduleIcon(
                                        notification?.id,
                                        notification?.navigatePath,
                                        notification?.subject,
                                      )}
                                      <p className="text-base font-bold ml-2">
                                        {notification?.subject}
                                      </p>
                                      {notification?.isRead === false && (
                                        <span
                                          className="w-3 h-3 rounded-full bg-indigo-600 absolute top-0 right-1 m-1"
                                          title="Unopened"
                                        ></span>
                                      )}
                                    </div>
                                    <p className="text-sm font-semibold ml-10">
                                      {notification?.message}
                                    </p>
                                    <span className="text-xs text-gray-500 font-bold ml-10">
                                      {getTime(notification?.createdAt)}
                                    </span>
                                    {notification?.isRead && (
                                      <button className="absolute top-2 right-2 p-1 hover:bg-gray-400 focus:outline-none">
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          className="h-4 w-4 text-gray-700"
                                          viewBox="0 0 20 20"
                                          fill="currentColor"
                                          aria-hidden="true"
                                        ></svg>
                                      </button>
                                    )}
                                  </div>
                                </a>
                              ))}
                              <div className="relative">
                                <div className="flex justify-center">
                                  <button
                                    className="text-blue-500 hover:underline mt-2 flex gap-2"
                                    onClick={handleShowMore}
                                  >
                                    <span className="font-semibold text-sm">View All</span>
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      fill="none"
                                      viewBox="0 0 24 24"
                                      strokeWidth="1.5"
                                      stroke="currentColor"
                                      className="w-5 h-5"
                                    >
                                      <path
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                        d="M19.5 8.25l-7.5 7.5-7.5-7.5"
                                      />
                                    </svg>
                                  </button>
                                </div>
                              </div>
                            </>
                          )}
                        </div>
                      </div>
                    )}
                    {/* Profile dropdown */}
                    <Menu as="div" className="hidden md:block relative ml-3">
                      <div>
                        <Menu.Button className="flex rounded-full bg-white text-sm focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
                          <span className="sr-only">Open user menu</span>
                          {currentProfile ? (
                            <span className="inline-flex h-8 w-8 items-center justify-center rounded-full bg-indigo-500">
                              <span className="text-md  leading-none text-white">
                                {profileIconName}
                              </span>
                            </span>
                          ) : (
                            <img
                              className="h-8 w-8 rounded-full"
                              src="https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
                              alt=""
                            />
                          )}
                        </Menu.Button>
                      </div>
                      <Transition
                        as={Fragment}
                        enter="transition ease-out duration-200"
                        enterFrom="transform opacity-0 scale-95"
                        enterTo="transform opacity-100 scale-100"
                        leave="transition ease-in duration-75"
                        leaveFrom="transform opacity-100 scale-100"
                        leaveTo="transform opacity-0 scale-95"
                      >
                        <Menu.Items className="absolute right-0 z-20 mt-2 w-64 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                          <Menu.Item>
                            <div className="p-4 border-b">
                              <div className="flex">
                                <div>
                                  <span className="inline-flex h-8 w-8 items-center justify-center rounded-full bg-indigo-500">
                                    <span className="text-md  leading-none text-white">
                                      {profileIconName}
                                    </span>
                                  </span>
                                </div>
                                <div className="pl-2">
                                  <p className="text-sm font-bold">
                                    {currentProfile?.firstName} {currentProfile?.lastName}
                                  </p>
                                  <p className="text-xs text-slate-500">
                                    {currentProfile?.employeeId}
                                  </p>
                                </div>
                              </div>
                              <p className="text-xs pt-2">
                                {currentProfile?.roles.map((_role) => (
                                  <span className="inline-flex items-center rounded-md bg-indigo-50 px-2 py-1 text-xs text-indigo-700 ring-1 ring-inset ring-indigo-700/10">
                                    {_role.label}
                                  </span>
                                ))}
                              </p>
                            </div>
                          </Menu.Item>
                          <Menu.Item>
                            {({ active }) => (
                              <Link
                                to="/coreV2/user/settings"
                                className={classNames(
                                  active ? 'bg-gray-100' : '',
                                  'block px-4 py-2 text-sm text-gray-700',
                                )}
                              >
                                Settings
                              </Link>
                            )}
                          </Menu.Item>

                          <Menu.Item>
                            {({ active }) => (
                              <a
                                href={ROOT_PATH}
                                onClick={userLogout}
                                className={classNames(
                                  active ? 'bg-gray-100' : '',
                                  'block px-4 py-2 text-sm text-gray-700',
                                )}
                              >
                                Sign out
                              </a>
                            )}
                          </Menu.Item>
                        </Menu.Items>
                      </Transition>
                    </Menu>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </Disclosure>
    </div>
  );
}

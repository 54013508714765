import { useMatches, useLoaderData, useSearchParams } from 'react-router-dom';
import GridLayout from '../components/GridLayout';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  REGION_GATEWAY,
  PUSHER_CLUSTER,
  PUSHER_KEY,
  NOTIFICATION_SERVICE,
  WSHOST,
  WSPORT,
} from '../utils/constants';
import { setFragmentData, setTour, setTourConfig } from '../features/screen/screenSlice';
import { useSelector } from 'react-redux';
import Lottie from 'lottie-react';
import loadingImage from '../animations/loadingPageImg.json';
import { sendRequest } from '../utils/apiCall';
import NavBar from '../components/NavBar';
import { triggerNotification } from '../features/screen/notificationSlice';
import Notification from '../components/Notification';
import Tour from 'reactour';
import Pusher from 'pusher-js';
import { getFragmentKey, getProfileFromLocalStorage } from '../utils/helperFunctions';
import { screenLoader } from '../utils/loaders';
import { useAppContext } from '../provider/AppProvider';
import NotificationAlert from '../components/notifications/NotificationAlert';

export default function Screen() {
  const { params } = useMatches()[0];
  let fragmentKey = getFragmentKey(params);
  let [searchParams] = useSearchParams();
  const { service, sId, id, screen } = params;
  const [layout, setLayout] = useState(null);
  const [layoutId, setLayoutId] = useState(null);
  const [entity, setEntity] = useState(null);
  const [extensionEntity, setExtensionEntity] = useState(null);

  const [loading, setLoading] = useState(true);
  const tour = useSelector((state) => state.screen.tour);
  const tourConfig = useSelector((state) => state.screen.tourConfig);
  const dispatch = useDispatch();
  const notification = useSelector((state) => state.notification);
  const currentProfile = getProfileFromLocalStorage();
  let employeeId = currentProfile?._id;
  const { setNotify, notify } = useAppContext();
  useEffect(() => {
    if (id) {
      const getFieldData = async () => {
        try {
          let formattedSId = sId.split('-')[0];
          let { response } = await sendRequest({
            url: `${REGION_GATEWAY}/${service}/instances/${formattedSId}/${id}`,
            method: 'GET',
          });
          if (response.status === 200) {
            const fieldData = response.data.data;
            dispatch({
              ...setFragmentData(fieldData),
              fragmentKey: fragmentKey,
            });
          } else {
            dispatch(triggerNotification(response.data.message, 'error'));
          }
        } catch (error) {
          console.log(error);
        }
      };
      getFieldData();
    }
    return () => {
      dispatch({
        ...setFragmentData({}),
        fragmentKey: fragmentKey,
      });
    };
  }, [service, sId, id, screen, dispatch, tour, params, fragmentKey]);

  useEffect(() => {
    (async () => {
      setLoading(true);
      const result = await screenLoader(params);
      console.log('Layout data');
      console.log(result);
      setLayout(result.layout);
      setEntity(result.entity);
      setExtensionEntity(result.extensionEntity);
      setLayoutId(result.layoutId);
      setLoading(false);
    })();
  }, [service, sId, id, screen]);
  useEffect(() => {
    Pusher.logToConsole = false;
    if (employeeId) {
      const pusher =
        NOTIFICATION_SERVICE === 'soketi'
          ? new Pusher(PUSHER_KEY, {
              cluster: PUSHER_CLUSTER,
              wsHost: WSHOST,
              wsPort: WSPORT,
              forceTLS: false,
              encrypted: true,
              disableStats: true,
              enabledTransports: ['ws', 'wss'],
            })
          : new Pusher(PUSHER_KEY, {
              cluster: PUSHER_CLUSTER,
            });
      const channel = pusher.subscribe(employeeId);
      channel.bind('notifications', function (data) {
        console.log('Received event Namespace:', data.eventNameSpace);
        if (data.eventNameSpace) {
          console.log('Received push notification:', data.message);
          setNotify(data);
        }
      });

      return () => {
        channel.unbind('notifications');
        pusher.unsubscribe(employeeId);
      };
    }
  }, [employeeId]);

  const loader = (
    <div style={{ margin: 'auto' }}>
      <Lottie
        className="m-auto"
        style={{ width: '70%' }}
        animationData={loadingImage}
        loop={true}
      />
    </div>
  );

  const closeTour = () => {
    dispatch(setTourConfig(undefined));
    dispatch(setTour(false));
  };

  if (loading) {
    return loader;
  }

  return (
    <>
      {/* TODO: TOUR WIP */}
      <Tour
        steps={
          tourConfig && tourConfig.length
            ? JSON.parse(tourConfig).steps.length
              ? JSON.parse(tourConfig).steps
              : []
            : []
        }
        isOpen={tour}
        key={Math.random()}
        onRequestClose={() => {
          closeTour();
        }}
      />
      <div>
        <Notification
          type={notification.type}
          message={notification.message}
          status={notification.status}
        />
      </div>
      {notify && <NotificationAlert notificationData={notify} />}
      {layout ? (
        <GridLayout
          key={`${layoutId}`}
          data={layout?.components?.[0]}
          actions={layout?.actions}
          entity={entity}
          extensionEntity={extensionEntity}
          params={params}
          query={searchParams}
          fields={layout?.fields}
        />
      ) : (
        loader
      )}
    </>
  );
}

import { Disclosure, Transition } from '@headlessui/react';
import { Fragment, useEffect, useState } from 'react';
import Icons from './SideNavbarIcons';
import { Bars3Icon } from '@heroicons/react/24/outline';
import { P } from './twEssential';
import { XMarkIcon } from '@heroicons/react/24/solid';
import '../App.css';
import { ShouldRenderComponent } from './ShouldRenderComponent';
import Tooltip from './Tooltip';
import { useSideNav } from '../hooks/useSideNav';
import { Link } from 'react-router-dom';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

const SideNavbar = (props) => {
  const [menu, setMenu] = useState([]);
  const [navMenu, setNavMenu] = useState([]);
  const { getSideNav } = useSideNav();
  const [collapseButton, setCollapseButton] = useState(false);
  const [isChildActive, setIsChildActive] = useState(false);

  useEffect(() => {
    (async () => {
      setMenu(await getSideNav());
    })();
  }, []);

  const storedItem = JSON.parse(localStorage.getItem('activeItem'));
  const navs = storedItem ? navMenu : menu;
  useEffect(() => {
    if (storedItem) {
      menu?.forEach((data) => {
        if (data.name === storedItem.name) {
          if (data.children) {
            data.current = true;
            data.children.forEach((child) => {
              child.isActive =
                child.name === storedItem?.children?.find((item) => item.isActive)?.name || false;
              setIsChildActive(child.isActive);
            });
          } else {
            data.current = true;
          }
        } else {
          data.current = false;
          if (data.children) {
            data.children.forEach((child) => {
              child.isActive = false;
              setIsChildActive(false);
            });
          }
        }
      });
      setNavMenu(menu);
    }
  }, [storedItem, menu]);

  const disclosureState = (item) => {
    menu?.forEach((data) => {
      if (data.name === item.name) {
        data.current = true;
      } else {
        data.current = false;
      }
      if (!item.children) localStorage.setItem('activeItem', JSON.stringify(item));
    });
  };

  const subItemActiveState = (item, subItem) => {
    menu?.forEach((data) => {
      if (data.name === item.name) {
        data.current = true;
        if (data.children) {
          data.children.forEach((child) => {
            child.isActive = child.name === subItem.name;
            setIsChildActive(true);
          });
        }
      } else {
        data.current = false;
        if (data.children) {
          data.children.forEach((child) => {
            child.isActive = false;
            setIsChildActive(false);
          });
        }
      }
    });
    setNavMenu(menu);
    localStorage.setItem('activeItem', JSON.stringify(item));
  };

  const handleToggleSidebar = (item) => {
    if (item.children) {
      setCollapseButton(!collapseButton);
    }
  };

  return (
    <div className="hidden md:block" data-tour="side-nav-bar">
      {!collapseButton ? (
        <div className="w-16 bg-white h-screen flex items-center flex-col pt-2 rounded-tr-xl rounded-br-xl shadow-lg">
          <button onClick={() => setCollapseButton(!collapseButton)}>
            <Bars3Icon
              className="w-7 text-[#6661FA] mt-1 font-semibold "
              data-bs-toggle="collapse"
              role="button"
              aria-expanded="false"
              aria-controls="collapseWithScrollbar"
            />
          </button>
          <div className="mt-2 w-full">
            {menu &&
              menu?.length > 0 &&
              menu?.map((item) => {
                const ItemIcon = Icons[item.icon];
                if (item.children) {
                  return (
                    <ShouldRenderComponent visibilityCondition={item.visibility?.conditions}>
                      <div key={item.name} className="pb-1 relative group">
                        <Link
                          to={item.current && isChildActive ? item.href : window.location.href}
                          id={item.name.split(' ').join('-')}
                          className={
                            item.current === false
                              ? 'text-gray-500 bg-white hover:text-gray-600 hover:bg-[#EFF2FF] group flex w-full items-center py-2 pl-5 text-left text-sm font-medium focus:outline-none'
                              : 'text-gray-50 group flex w-full items-center py-2 pl-5 text-left text-sm font-medium  bg-[#6661FA]'
                          }
                          target={item.target}
                          onClick={(event) => {
                            event.preventDefault();
                            event.stopPropagation();
                            disclosureState(item);
                            handleToggleSidebar(item);
                          }}
                        >
                          <ItemIcon
                            key={item.name}
                            data-tour={item.tourId}
                            className={classNames(
                              item.current
                                ? 'text-gray-50'
                                : 'text-gray-500 group-hover:text-gray-500',
                              'mr-3 h-6 w-6 flex-shrink-0',
                            )}
                            aria-hidden="true"
                          />
                        </Link>
                        <Tooltip content={item.name} type={'single'} />
                      </div>
                    </ShouldRenderComponent>
                  );
                } else {
                  return (
                    <ShouldRenderComponent visibilityCondition={item.visibility?.conditions}>
                      <div
                        key={item.name}
                        className="pb-1 relative group"
                        onClick={() => disclosureState(item)}
                      >
                        <Link
                          to={item.href}
                          id={item.name.split(' ').join('-')}
                          className={
                            item.current === false
                              ? 'text-gray-500 bg-white hover:text-gray-600 hover:bg-[#EFF2FF] group flex w-full items-center py-2 pl-5 text-left text-sm font-medium focus:outline-none'
                              : 'text-gray-50 group flex w-full items-center py-2 pl-5 text-left text-sm font-medium  bg-[#6661FA]'
                          }
                          target={item.target}
                        >
                          <ItemIcon
                            key={item.name}
                            data-tour={item.tourId}
                            className={classNames(
                              item.current
                                ? 'text-gray-50'
                                : 'text-gray-500 group-hover:text-gray-500',
                              'mr-3 h-6 w-6 flex-shrink-0',
                            )}
                            aria-hidden="true"
                            onClick={() => handleToggleSidebar(item)}
                          />
                        </Link>
                        <Tooltip content={item.name} type={'single'} />
                      </div>
                    </ShouldRenderComponent>
                  );
                }
              })}
          </div>
        </div>
      ) : (
        <>
          {/* Sidebar */}
          <Transition.Root show={collapseButton} as={Fragment}>
            <div className="fixed inset-0 z-50">
              <Transition.Child
                as={Fragment}
                enter="transition-opacity ease-linear duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="transition-opacity ease-linear duration-300"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <div
                  className={`fixed inset-0 bg-black ${
                    collapseButton
                      ? 'opacity-50 pointer-events-auto'
                      : 'opacity-0 pointer-events-none'
                  }`}
                  onClick={() => setCollapseButton(!collapseButton)}
                />
              </Transition.Child>
              <Transition.Child
                as={Fragment}
                enter="transition-transform ease-in-out duration-1000"
                enterFrom="-translate-x-full"
                enterTo="translate-x-0"
                leave="transition-transform ease-in-out duration-1000"
                leaveFrom="translate-x-0"
                leaveTo="-translate-x-full"
              >
                <div className="fixed inset-y-0 left-0 w-72 bg-white z-50 flex flex-col justify-between  rounded-tr-xl rounded-br-xl shadow-lg">
                  {/* Sidebar content */}
                  <div>
                    <div className="flex justify-between items-center ">
                      <div className="flex gap-4 py-3">
                        <div
                          onClick={() => {
                            setCollapseButton(!collapseButton);
                          }}
                          className="hidden sm:block w-9 hover:bg-[#6661FA] p-1 rounded-full bg-[#6661FA] ml-2"
                        >
                          <XMarkIcon
                            className="text-white"
                            data-bs-toggle="collapse"
                            href="#collapseWithScrollbar"
                            role="button"
                            aria-expanded="false"
                            aria-controls="collapseWithScrollbar"
                          />
                        </div>
                        <P className="text-indigo-400 text-xs font-bold pt-1">Close Menu</P>
                      </div>
                    </div>
                    <hr className="w-full" />
                    <div className="overflow-x-hidden overflow-y-scroll h-[88vh] scrollWithoutDefault">
                      {menu?.map((item) => {
                        const ItemIcon = Icons[item.icon];
                        return !item.children ? (
                          <ShouldRenderComponent visibilityCondition={item.visibility?.conditions}>
                            <div key={item.name} className=" pb-1">
                              <Link
                                to={item.href}
                                onClick={() => {
                                  disclosureState(item);
                                  setCollapseButton(!collapseButton);
                                }}
                                className={
                                  item.current === false
                                    ? 'text-gray-500 bg-white hover:text-gray-600 hover:bg-[#EFF2FF] group flex w-full items-center py-2 pl-3 text-left text-sm font-medium focus:outline-none'
                                    : 'text-gray-50 group flex w-full items-center py-2 pl-3 text-left text-sm font-medium  bg-[#6661FA]'
                                }
                                target={item.target}
                              >
                                <ItemIcon
                                  key={item.name}
                                  className={classNames(
                                    item.current
                                      ? 'text-gray-50'
                                      : 'text-gray-500 group-hover:text-gray-500',
                                    'mr-3 h-6 w-6 flex-shrink-0',
                                  )}
                                  aria-hidden="true"
                                />
                                <span className="flex-1 pl-1">{item.name}</span>
                              </Link>
                            </div>
                          </ShouldRenderComponent>
                        ) : (
                          <ShouldRenderComponent visibilityCondition={item.visibility?.conditions}>
                            <Disclosure
                              as="div"
                              key={item.name}
                              className="space-y-1 pb-1"
                              defaultOpen={item.current}
                            >
                              {({ open }) => (
                                <>
                                  <Disclosure.Button
                                    className={
                                      'text-gray-500 hover:text-gray-600 hover:bg-[#EFF2FF] group flex w-full items-center py-2 pl-2 text-left text-sm font-medium focus:outline-none'
                                    }
                                  >
                                    <ItemIcon
                                      className="mr-3 h-6 w-8 flex-shrink-0 text-gray-500 group-hover:text-gray-500 items-center"
                                      aria-hidden="true"
                                    />
                                    <span className="flex-1">{item.name}</span>
                                    <svg
                                      className={classNames(
                                        open
                                          ? 'rotate-90 text-gray-400 hover:text-gray-500'
                                          : 'text-gray-400 hover:text-gray-500',
                                        'ml-3 h-5 w-5 flex-shrink-0 transform transition-colors duration-150 ease-in-out group-hover:text-gray-500 mr-5',
                                      )}
                                      viewBox="0 0 20 20"
                                      aria-hidden="true"
                                    >
                                      <path d="M6 6L14 10L6 14V6Z" fill="currentColor" />
                                    </svg>
                                  </Disclosure.Button>
                                  <Disclosure.Panel className="space-y-1 bg-[#EFF2FF] bg-opacity-50">
                                    {({ close }) =>
                                      item?.children?.map((subItem) => (
                                        <ShouldRenderComponent
                                          visibilityCondition={subItem.visibility?.conditions}
                                        >
                                          <Link
                                            key={subItem.name}
                                            to={subItem.href}
                                            onClick={() => {
                                              subItemActiveState(item, subItem);
                                              close();
                                              setCollapseButton(!collapseButton);
                                            }}
                                            className={
                                              subItem.isActive === true
                                                ? 'text-gray-50 hover:text-gray-600 hover:bg-[#EFF2FF] group flex w-full items-center py-2 pl-2 ml-1 text-left text-sm font-medium bg-[#6661FA]'
                                                : 'text-gray-500 hover:text-gray-600 hover:bg-[#EFF2FF] group flex w-full items-center py-2 pl-2 ml-1 text-left text-sm font-medium focus:outline-none'
                                            }
                                          >
                                            <div className="flex pl-1.5 items-center">
                                              <span className="ml-10">{subItem.name}</span>
                                            </div>
                                          </Link>
                                        </ShouldRenderComponent>
                                      ))
                                    }
                                  </Disclosure.Panel>
                                </>
                              )}
                            </Disclosure>
                          </ShouldRenderComponent>
                        );
                      })}
                    </div>
                  </div>
                </div>
              </Transition.Child>
            </div>
          </Transition.Root>
        </>
      )}
    </div>
  );
};

export default SideNavbar;

import { useMatches, useSearchParams } from 'react-router-dom';
import GridLayout from '../components/GridLayout';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  REGION_GATEWAY,
  PUSHER_CLUSTER,
  PUSHER_KEY,
  NOTIFICATION_SERVICE,
  WSPORT,
  WSHOST,
} from '../utils/constants';
import {
  setLoading,
  setFragmentData,
  setTour,
  setTourConfig,
} from '../features/screen/screenSlice';
import { useSelector } from 'react-redux';
import Lottie from 'lottie-react';
import loadingImage from '../animations/loadingPageImg.json';
import { sendRequest } from '../utils/apiCall';
import NavBar from '../components/NavBar';
import { triggerNotification } from '../features/screen/notificationSlice';
import Notification from '../components/Notification';
import Tour from 'reactour';
import Pusher from 'pusher-js';
import { getFragmentKey, getProfileFromLocalStorage } from '../utils/helperFunctions';
import { useDashboard } from '../hooks/useDashboard';
import NotificationAlert from '../components/notifications/NotificationAlert';
import { useAppContext } from '../provider/AppProvider';

export default function DashboardScreen() {
  const { params } = useMatches()[0];
  let fragmentKey = getFragmentKey(params);
  let [searchParams] = useSearchParams();
  const { service, sId, id, screen } = params;
  const [layout, setLayout] = useState(null);
  const { getDashboard } = useDashboard();
  const [loading, setLoading] = useState(true);
  const tour = useSelector((state) => state.screen.tour);
  const tourConfig = useSelector((state) => state.screen.tourConfig);
  const dispatch = useDispatch();
  const notification = useSelector((state) => state.notification);
  const currentProfile = getProfileFromLocalStorage();
  let employeeId = currentProfile?._id;
  const { setNotify, notify } = useAppContext();

  useEffect(() => {
    (async () => {
      const _layout = await getDashboard();
      setLayout(_layout);
      setLoading(!loading);
    })();
  }, []);

  useEffect(() => {
    Pusher.logToConsole = false;
    if (employeeId) {
      const pusher =
        NOTIFICATION_SERVICE === 'soketi'
          ? new Pusher(PUSHER_KEY, {
              cluster: PUSHER_CLUSTER,
              wsHost: WSHOST,
              wsPort: WSPORT,
              forceTLS: false,
              encrypted: true,
              disableStats: true,
              enabledTransports: ['ws', 'wss'],
            })
          : new Pusher(PUSHER_KEY, {
              cluster: PUSHER_CLUSTER,
            });
      const channel = pusher.subscribe(employeeId);
      channel.bind('notifications', function (data) {
        console.log('Received event Namespace:', data.eventNameSpace);
        if (data.eventNameSpace) {
          console.log('Received push notification:', data.message);
          setNotify(data);
        }
      });

      return () => {
        channel.unbind('notifications');
        pusher.unsubscribe(employeeId);
      };
    }
  }, [employeeId]);

  if (loading) {
    return (
      <div style={{ margin: 'auto' }}>
        <Lottie
          className="m-auto"
          style={{ width: '70%' }}
          animationData={loadingImage}
          loop={true}
        />
      </div>
    );
  }
  const closeTour = () => {
    dispatch(setTourConfig(undefined));
    dispatch(setTour(false));
  };
  return (
    <>
      {/* TODO: TOUR WIP */}
      <Tour
        steps={
          tourConfig && tourConfig.length
            ? JSON.parse(tourConfig).steps.length
              ? JSON.parse(tourConfig).steps
              : []
            : []
        }
        isOpen={tour}
        key={Math.random()}
        onRequestClose={() => {
          closeTour();
        }}
      />
      {notify && <NotificationAlert notificationData={notify} />}
      <div>
        <Notification
          type={notification.type}
          message={notification.message}
          status={notification.status}
        />
      </div>

      <GridLayout
        key={`${service}-${sId}-${screen}-${id}`}
        data={layout?.components[0]}
        actions={layout?.actions}
        entity={null}
        extensionEntity={null}
        params={params}
        query={searchParams}
        fields={layout?.fields}
      />
    </>
  );
}

import { Fragment } from 'react';
import { Dialog } from '@headlessui/react';
import { Transition } from '@headlessui/react';
import { useEffect } from 'react';
import { ChevronRightIcon, EyeIcon, EyeSlashIcon } from '@heroicons/react/24/outline';
import { CheckCircleIcon, XCircleIcon } from '@heroicons/react/24/solid';
import { useState } from 'react';
import { AZURE_AD_CLIENT_ID, LOGIN_UI, REGION_GATEWAY } from '../../utils/constants';
import { Input } from '../twEssential';
import { sendRequest } from '../../utils/apiCall';
import '../../App.css';
import { formatValue, getProfileFromLocalStorage } from '../../utils/helperFunctions';
import { AzureSignIn } from '../../auth/AzureSignIn';

import { useMsal, useIsAuthenticated } from '@azure/msal-react';
import {
  InteractionStatus,
  InteractionType,
  InteractionRequiredAuthError,
} from '@azure/msal-browser';
import { callApi } from '../../auth/callApi';
import { getCookie, eraseCookie } from '../../utils/storageHelpers';

export default function Profile(props) {
  const [user, setUser] = useState({});
  const [reporter, setReporter] = useState('');
  const { instance, inProgress } = useMsal();
  const [apiData, setApiData] = useState(null);

  useEffect(() => {
    if (!apiData && inProgress === InteractionStatus.None) {
      callApi(null)
        .then((response) => {
          return setApiData(response);
        })
        .catch((e) => {
          if (e instanceof InteractionRequiredAuthError) {
            instance.acquireTokenRedirect({
              ...loginRequest,
              account: instance.getActiveAccount(),
            });
          }
        });
    }
  }, [inProgress, apiData, instance]);

  useEffect(() => {
    const getFieldData = async () => {
      try {
        let { response } = await sendRequest({
          url: `${REGION_GATEWAY}/coreV2/getProfile`,
          method: 'GET',
        });
        if (response.status === 200) {
          const fieldData = response.data.data;
          setUser(fieldData);
        }
      } catch (error) {
        console.log(error);
      }
    };
    getFieldData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const getReoprtingTo = async () => {
      try {
        let { response } = await sendRequest({
          url: `${REGION_GATEWAY}/coreV2/instances/user/${user?.reportingTo}`,
          method: 'GET',
        });
        if (response.status === 200 && response) {
          const data = response.data.data;
          const reporterName = `${data?.firstName} ${data?.lastName}`;
          setReporter(reporterName);
        }
      } catch (error) {
        console.log(error);
      }
    };
    if (user?.reportingTo) {
      getReoprtingTo();
    }
  }, [user]);

  let profileName = user.firstName;
  let profileIconName;
  if (profileName) {
    profileIconName = profileName
      .split(' ')
      .map((name) => name[0])
      .join('')
      .toUpperCase();
  }

  const [changePassword, setChangePassword] = useState(false);
  return (
    <div className="scrollStyle m-5">
      <div className="divide-y divide-gray-200 overflow-hidden rounded-lg bg-white shadow">
        <div className="px-4 py-5 sm:p-6">
          <div className="rounded-lg">
            <div className="mx-auto max-w-3xl py-4 sm:px-6 md:flex md:items-center md:justify-between md:space-x-5 lg:max-w-7xl lg:px-8">
              <div className="flex items-center space-x-5">
                <div className="flex-shrink-0">
                  <div className="relative">
                    <span className="inline-flex h-32 w-32 items-center justify-center rounded-full bg-indigo-500">
                      <span className="text-6xl  leading-none text-white">{profileIconName}</span>
                    </span>
                    <span
                      className="absolute inset-0 rounded-full shadow-inner"
                      aria-hidden="true"
                    />
                  </div>
                </div>
                <div>
                  <h1 className="text-3xl font-bold text-gray-900">{`${user.firstName} ${user.lastName}`}</h1>
                  <p className="text-sm font-medium text-gray-500">{user.employeeId}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="divide-y divide-gray-200 overflow-hidden bg-gray-300 shadow sm:grid sm:grid-cols-2 sm:gap-px sm:divide-y-0">
          <div className="px-4 py-4 sm:px-6 text-center" style={{ background: '#F9FAFB' }}>
            <p className="text-gray-500 text-sm">Email Address</p>
            {user.email ? (
              <p className="text-lg">{user.email}</p>
            ) : (
              <p className="text-lg">Not Avaialble</p>
            )}
          </div>
          <div className="px-4 py-4 sm:px-6 text-center" style={{ background: '#F9FAFB' }}>
            <p className="text-gray-500 text-sm">Phone Number</p>
            {user.mobile ? (
              <p className="text-lg">{user.mobile}</p>
            ) : (
              <p className="text-lg">Not Available</p>
            )}
          </div>
        </div>
      </div>
      <div className="divide-y divide-gray-200 overflow-hidden rounded-lg bg-white shadow mt-10">
        <div className="px-4 py-5 sm:p-6 flex flex-wrap items-center gap-5">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            fill="blue"
            className="w-6 h-6"
          >
            <path
              fill-rule="evenodd"
              d="M7.5 6a4.5 4.5 0 119 0 4.5 4.5 0 01-9 0zM3.751 20.105a8.25 8.25 0 0116.498 0 .75.75 0 01-.437.695A18.683 18.683 0 0112 22.5c-2.786 0-5.433-.608-7.812-1.7a.75.75 0 01-.437-.695z"
              clip-rule="evenodd"
            />
          </svg>
          <h1 className="text-xl font-medium">My Details</h1>
        </div>
        <div className="divide-y divide-gray-300 overflow-hidden bg-gray-300 shadow sm:grid sm:grid-cols-3 sm:gap-px sm:divide-y-0">
          <div className="px-4 py-10 sm:px-6 text-center" style={{ background: 'white' }}>
            <p className="text-gray-500 text-sm">Title</p>
            {user.title ? <p className="text-lg">{user.title}</p> : <p className="text-lg">-</p>}
          </div>
          <div className="px-4 py-10 sm:px-6 text-center" style={{ background: 'white' }}>
            <p className="text-gray-500 text-sm">Department</p>
            {user.department ? (
              <p className="text-lg">{user.department}</p>
            ) : (
              <p className="text-lg">-</p>
            )}
          </div>
          <div className="px-4 py-10 sm:px-6 text-center" style={{ background: 'white' }}>
            <p className="text-gray-500 text-sm">Reporting To</p>
            {user.reportingTo ? (
              <p className="text-lg">{reporter}</p>
            ) : (
              <p className="text-lg">-</p>
            )}
          </div>
          <div className="px-4 py-10 sm:px-6 text-center" style={{ background: 'white' }}>
            <p className="text-gray-500 text-sm">Date Of Birth</p>
            {user?.dob ? (
              <p className="text-lg">{formatValue(user.dob, 'DD-MMM-YYYY', 'Date')}</p>
            ) : (
              <p className="text-lg">-</p>
            )}
          </div>
          <div className="px-4 py-10 sm:px-6 text-center" style={{ background: 'white' }}>
            <p className="text-gray-500 text-sm">Date of Joining</p>
            {user.doj ? (
              <p className="text-lg">{formatValue(user.doj, 'DD-MMM-YYYY', 'Date')}</p>
            ) : (
              <p className="text-lg">-</p>
            )}
          </div>
          <div className="px-4 py-10 sm:px-6 text-center" style={{ background: 'white' }}></div>
        </div>
      </div>

      <div className="divide-y divide-gray-200 overflow-hidden rounded-lg bg-white shadow mt-10">
        <div className=" max-w-fill py-6 sm:px-6 md:flex md:items-center md:justify-between md:space-x-5 lg:max-w-7xl lg:px-8">
          <div className="flex items-center space-x-5">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              fill="red"
              className="w-6 h-6"
            >
              <path
                fill-rule="evenodd"
                d="M12 1.5a5.25 5.25 0 00-5.25 5.25v3a3 3 0 00-3 3v6.75a3 3 0 003 3h10.5a3 3 0 003-3v-6.75a3 3 0 00-3-3v-3c0-2.9-2.35-5.25-5.25-5.25zm3.75 8.25v-3a3.75 3.75 0 10-7.5 0v3h7.5z"
                clip-rule="evenodd"
              />
            </svg>
            <h1 className="text-xl font-medium">Security</h1>
          </div>
        </div>
        <div className="flex">
          <button
            className="px-4 py-5 sm:p-6 text-left w-full"
            onClick={() => setChangePassword(true)}
          >
            <p className="px-10 text-m">Change Password</p>
            <p className="px-10 text-sm text-gray-500">
              {' '}
              Last Changed: {formatValue(user.passwordUpdatedAt, 'DD-MMM-YYYY', 'Date')}
            </p>
          </button>
          <button className="mr-24 mt-6 space-y-4 space-y-reverse sm:flex-row-reverse sm:justify-end sm:space-y-0 sm:space-x-3 sm:space-x-reverse md:mt-0 md:flex-row md:space-x-3">
            <ChevronRightIcon className="h-8 w-8" onClick={() => setChangePassword(true)} />
          </button>
        </div>
        {/* Show Azure AD linking only if Azure AD Client ID is present */}
        {AZURE_AD_CLIENT_ID && AZURE_AD_CLIENT_ID.length > 0 && <AzureSignIn profile={apiData} />}
      </div>

      <ChangePasswordPopup
        changePassword={changePassword}
        setChangePassword={setChangePassword}
        showCancelButton="true"
        onShow={false}
      />
    </div>
  );
}

export let ChangePasswordPopup = (props) => {
  console.log(props);
  const { changePassword, setChangePassword, showCancelButton, onShow } = props;
  const [password, setPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [retypeNewPassword, setRetypeNewPassword] = useState('');
  const [errorMsg, setErrorMsg] = useState('');
  const [passwordPolicy, setPasswordPolicy] = useState(null);
  const [criteriaStatus, setCriteriaStatus] = useState({
    length: false,
    maxLength: false,
    uppercase: false,
    lowercase: false,
    number: false,
    specialChar: false,
  });
  const [isNewPasswordVisible, setIsNewPasswordVisible] = useState(false);
  const [isRetypePasswordVisible, setIsRetypePasswordVisible] = useState(false);
  const [isCurrentPasswordVisible, setIsCurrentPasswordVisible] = useState(false);

  const toggleNewPasswordVisibility = () => {
    setIsNewPasswordVisible(!isNewPasswordVisible);
  };
  const toggleRetypePasswordVisibility = () => {
    setIsRetypePasswordVisible(!isRetypePasswordVisible);
  };
  const toggleCurrentPasswordVisibility = () => {
    setIsCurrentPasswordVisible(!isCurrentPasswordVisible);
  };

  const currentProfile = getProfileFromLocalStorage();

  const validatePassword = async (_newPassword) => {
    if (!_newPassword) {
      setCriteriaStatus({
        length: false,
        maxLength: false,
        lowercase: false,
        uppercase: false,
        number: false,
        specialChar: false,
      });
      return;
    }

    try {
      let { response } = await sendRequest({
            url: `${REGION_GATEWAY}/coreV2/validatePassword`,
            method: 'POST',
            body: { newPassword: _newPassword }
          });

        if (response?.status) {
          console.log(response?.data); 
          setCriteriaStatus(response?.data?.criteriaStatus); 
          setPasswordPolicy(response?.data?.tenantPolicy);
        } else {
          console.error("Error:", response?.data?.message || "No response data");
    }
    } catch (error) {
      console.error("Error validating password:", error);
    }
  };

  let allCriteriaMet = Object.values(criteriaStatus).every(Boolean);

  const onClickChangePassword = async () => {
  try {
    if (newPassword === password) {
      setErrorMsg('The New password and Old password should not be same');
    } else {
      if (newPassword === retypeNewPassword) {
        
        if (!allCriteriaMet) {
          setErrorMsg('Password does not meet all criteria');
          return; 
        } else {
          console.log('Both passwords are correct');
          let { response, responseError } = await sendRequest({
            url: `${REGION_GATEWAY}/coreV2/changePassword/?employeeId=${currentProfile.employeeId}`,
            method: 'POST',
            body: {
              password: password,
              newPassword: newPassword,
            },
          });
          if (response?.status === 200) {
            eraseCookie('access_token');
            eraseCookie('holiFlag');
            localStorage.clear();
            window.location.replace(`${LOGIN_UI}`);
          } else {
            setErrorMsg(responseError.response.data.message);
            setNewPassword('');
            setRetypeNewPassword('');
          }
        }

      } else {
        if(!retypeNewPassword) {
          setErrorMsg('Please Retype the New password')
        } else if(newPassword !== retypeNewPassword) {
          setErrorMsg('The New password and confirmation password do not match');
        }
      }
    }
  } catch (error) {
    console.log(error);
  }
};

  return (
    <Transition.Root show={onShow ? true : changePassword} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={setChangePassword}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>
        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-5 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 w-1/2 sm:p-6 mx-20">
                <div className="px-4 items-left">
                  <h1 className="text-xl font-medium mt-4">Change Password</h1>
                  <p className="text-sm text-gray-500 font-medium py-2">
                    Please Enter New Password
                  </p>
                </div>
                <hr className="my-4 h-px bg-gray-200 border-0 dark:bg-gray-700" />
                <div className="items-center gap-3">
                  <div className="grid grid-cols-3 gap-10 items-end">
                    <label
                      htmlFor="name"
                      className="ml-px mt-2 block pl-4 text-l font-normal text-gray-700"
                    >
                      Current Password
                    </label>
                    <div className="flex items-center col-span-2">
                      <Input
                        value={password}
                        type={isCurrentPasswordVisible ? "text" : "password"}
                        name="name"
                        id="name"
                        className="block w-3/5 rounded-lg border-gray-300 px-4 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                        onChange={(e) => setPassword(e.target.value)}
                      />
                      <button
                        type="button"
                        onClick={toggleCurrentPasswordVisibility}
                        className="flex flex-end items-center pl-3 justify-end"
                      >
                        {isCurrentPasswordVisible ? (
                          <EyeIcon className="h-5 w-5 text-gray-500" />
                        ) : (
                          <EyeSlashIcon className="h-5 w-5 text-gray-500" />
                        )}
                      </button>
                    </div>
                  </div>
                  <hr className="my-4 h-px bg-gray-200 border-0 dark:bg-gray-700" />
                  <div className="grid grid-cols-3 gap-10 items-end">
                    <label
                      htmlFor="name"
                      className="ml-px block pl-4 text-l font-normal text-gray-700"
                    >
                      New Password
                    </label>
                    <div className="flex items-center col-span-2">
                      <Input
                        value={newPassword}
                        type={isNewPasswordVisible ? "text" : "password"}
                        name="name"
                        id="name"
                        className="block w-3/5 rounded-lg border-gray-300 px-4 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                        onChange={(e) => {setNewPassword(e.target.value); validatePassword(e.target.value)}}
                      />
                      <button
                        type="button"
                        onClick={toggleNewPasswordVisibility}
                        className="flex flex-end items-center pl-3 justify-end"
                      >
                        {isNewPasswordVisible ? (
                          <EyeIcon className="h-5 w-5 text-gray-500" />
                        ) : (
                          <EyeSlashIcon className="h-5 w-5 text-gray-500" />
                        )}
                      </button>
                    </div>
                  </div>
                  <hr className="my-4 h-px bg-gray-200 border-0 dark:bg-gray-700" />
                  <div className="grid grid-cols-3 gap-10 items-end">
                    <label
                      htmlFor="name"
                      className="ml-px block pl-4 text-l font-normal text-gray-700"
                    >
                      Retype New Password
                    </label>
                    <div className="flex items-center col-span-2">
                      <Input
                        value={retypeNewPassword}
                        type={isRetypePasswordVisible ? "text" : "password"}
                        name="name"
                        id="name"
                        className="block w-3/5 rounded-lg border-gray-300 px-4 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                        onChange={(e) => setRetypeNewPassword(e.target.value)}
                      />
                      <button
                        type="button"
                        onClick={toggleRetypePasswordVisibility}
                        className="flex flex-end items-center pl-3 justify-end"
                      >
                        {isRetypePasswordVisible ? (
                          <EyeIcon className="h-5 w-5 text-gray-500" />
                        ) : (
                          <EyeSlashIcon className="h-5 w-5 text-gray-500" />
                        )}
                      </button>
                    </div>
                  </div>
                  {errorMsg ? (
                    <div className="mt-8 sm:mt-6 flex flex-wrap justify-center gap-5 text-sm text-red-500">
                      {errorMsg}
                    </div>
                  ) : null}

                  {newPassword && (
                    <div className="border p-4 mt-4 rounded-md shadow-md">
                      <p className="text-sm pb-4">Your New Password must contain : </p>
                      <ul>
                        {passwordPolicy?.minNumOfCharacter && <li className="flex items-center gap-2 text-sm">
                          <span
                            className={`inline-block w-6 h-6 rounded-full text-gray-500 ${
                              criteriaStatus.length ? "text-green-500" : "text-red-500"
                            }`}
                          >
                            {criteriaStatus.length ? <CheckCircleIcon /> : <XCircleIcon />}
                          </span>
                          At least {passwordPolicy?.minNumOfCharacter} character(s)
                        </li>}
                        {passwordPolicy?.maxNumOfCharacter && <li className="flex items-center gap-2 text-sm">
                          <span
                            className={`inline-block w-6 h-6 rounded-full text-gray-500 ${
                              criteriaStatus.maxLength ? "text-green-500" : "text-red-500"
                            }`}
                          >
                            {criteriaStatus.maxLength ? <CheckCircleIcon /> : <XCircleIcon />}
                          </span>
                          Not More than {passwordPolicy?.maxNumOfCharacter} characters
                        </li>}
                        {passwordPolicy?.minNumOfLowerCase && <li className="flex items-center gap-2 text-sm">
                          <span
                            className={`inline-block w-6 h-6 rounded-full text-gray-500 ${
                              criteriaStatus.lowercase ? "text-green-500" : "text-red-500"
                            }`}
                          >
                            {criteriaStatus.lowercase ? <CheckCircleIcon /> : <XCircleIcon />}
                          </span>
                          At least {passwordPolicy?.minNumOfLowerCase} lowercase character(s)
                        </li>}
                        {passwordPolicy?.minNumOfUpperCase && <li className="flex items-center gap-2 text-sm">
                          <span
                            className={`inline-block w-6 h-6 rounded-full text-gray-500 ${
                              criteriaStatus.uppercase ? "text-green-500" : "text-red-500"
                            }`}
                          >
                            {criteriaStatus.uppercase ? <CheckCircleIcon /> : <XCircleIcon />}
                          </span>
                          At least {passwordPolicy?.minNumOfUpperCase} uppercase character(s)
                        </li>}
                        {passwordPolicy?.minNumOfDigit && <li className="flex items-center gap-2 text-sm">
                          <span
                            className={`inline-block w-6 h-6 rounded-full text-gray-500 ${
                              criteriaStatus.number ? "text-green-500" : "text-red-500"
                            }`}
                          >
                            {criteriaStatus.number ? <CheckCircleIcon /> : <XCircleIcon />}
                          </span>
                          At least {passwordPolicy?.minNumOfDigit} digit(s)
                        </li>}
                        {passwordPolicy?.minNumOfSpecialCharacter && <li className="flex items-center gap-2 text-sm">
                          <span
                            className={`inline-block w-6 h-6 rounded-full text-gray-500 ${
                              criteriaStatus.specialChar
                                ? "text-green-500"
                                : "text-red-500"
                            }`}
                          >
                            {criteriaStatus.specialChar ? <CheckCircleIcon /> : <XCircleIcon />}
                          </span>
                          At least {passwordPolicy?.minNumOfSpecialCharacter} special character(s) 
                        </li>}

                        {passwordPolicy?.allowedSpecialCharacter && <li className="flex items-center gap-2 text-sm">
                          <span
                            className={`inline-block w-6 h-6 rounded-full text-gray-500 ${
                              criteriaStatus.allowedSpecialChar
                                ? "text-green-500"
                                : "text-red-500"
                            }`}
                          >
                            {criteriaStatus.allowedSpecialChar ? <CheckCircleIcon /> : <XCircleIcon />}
                          </span>
                          Allowed special characters are {passwordPolicy?.allowedSpecialCharacter}
                        </li>}    
                      </ul>
                    </div>
                  )}
                  {/* <div className="mt-5 sm:mt-6 flex flex-wrap justify-end gap-5">

                                        <div>
                                            <button
                                                type="button"
                                                className="rounded-md  border border-transparent bg-gray-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-gray-700 sm:text-sm w-24"
                                                onClick={() => { setChangePassword(false) }}
                                            >
                                                Cancel
                                            </button></div>
                                        <div>

                                            <button
                                                type="submit"
                                                className="rounded-md border border-transparent bg-indigo-500 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-indigo-700 sm:text-sm w-24"
                                                onClick={() => {
                                                    onClickChangePassword()
                                                    setChangePassword(false)

                                                }}


                                            >
                                                Save
                                            </button></div>
                                    </div> */}
                  <div className="mt-8 sm:mt-6 flex flex-wrap justify-center gap-5">
                    {showCancelButton === 'true' && (
                      <button
                        type="button"
                        className="rounded-md  border border-transparent bg-gray-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-gray-700 sm:text-base w-32 mt-2"
                        onClick={() => {
                          setChangePassword(false);
                        }}
                      >
                        Cancel
                      </button>
                    )}
                    <button
                      type="submit"
                      className="rounded-md disabled:bg-indigo-300 disabled:cursor-not-allowed border border-transparent bg-indigo-500 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-indigo-700 sm:text-base w-32 mt-2"
                      disabled={!allCriteriaMet}
                      onClick={() => {
                        onClickChangePassword();
                        if (!errorMsg) {
                          setChangePassword(true);
                        } else {
                          setChangePassword(false);
                        }
                      }}
                    >
                      Save
                    </button>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

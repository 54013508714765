import moment from 'moment';
import _ from 'lodash';
import { JSONPath } from 'jsonpath-plus';

const today = moment();
const yesterday = moment().subtract(1, 'day');

export function getFragmentKey(params) {
  // Get the current pathname to get the parent screen details
  const pathname = window.location.pathname;
  const pathnameParts = pathname?.split('/');
  const lastPathChunk = pathnameParts?.[pathnameParts.length - 1];

  if (Object?.keys(params)?.length === 0) {
    // If params is an empty object, set fragmentKey to "home"
    return 'home';
  } else {
    if (params.screen === lastPathChunk) {
      const fragmentKey = `${params.sId}-${params.screen}`;
      return fragmentKey;
    } else {
      const fragmentKey = `${params?.sId}-${params?.screen}-${lastPathChunk}`;
      return fragmentKey;
    }
  }
}

export function getProfileFromLocalStorage() {
  try {
    return JSON.parse(localStorage.getItem('profile'));
  } catch (e) {
    console.warn('Unable to parse profile data');
    return {};
  }
}

export function entryDoneByUser(currentProfile = getProfileFromLocalStorage()) {
  const entryDoneBy = {
    userId: currentProfile._id,
    firstName: currentProfile.firstName,
    lastName: currentProfile.lastName,
  };

  return entryDoneBy;
}

export function getTime(createdTime) {
  const now = new Date(); // current date and time
  const createdAt = new Date(createdTime); // ticket creation date and time
  const diff = now - createdAt; // difference in milliseconds
  const minutes = Math.floor(diff / (1000 * 60)); // convert milliseconds to minutes
  const hours = Math.floor(minutes / 60); // convert minutes to hours
  const days = Math.floor(hours / 24); // convert hours to days
  const remainingHours = hours % 24; // get the remaining hours if any
  const remainingMinutes = minutes % 60; // get the remaining minutes if any

  let timeString = 'Just now';
  if (days > 0) {
    timeString = `${days} day${days > 1 ? 's' : ''} ago`;
  } else if (remainingHours > 0) {
    timeString = `${remainingHours} hour${remainingHours > 1 ? 's' : ''} ago`;
  } else if (remainingMinutes > 0) {
    timeString = `${remainingMinutes} minute${remainingMinutes > 1 ? 's' : ''} ago`;
  }
  return timeString;
}

export function preProcessFieldJsonOfLayout(value, fragmentData, basedOnField) {
  if (fragmentData?.[basedOnField]) {
    const jsonPathExpression = value;
    const result = JSONPath({ path: jsonPathExpression, json: fragmentData?.[basedOnField] });
    return result; // Return the result if available
  }
}

export function formatValue(content, format, type) {
  if (type === 'Date') {
    return moment(content).format(format);
  }
  return content;
}
export function getAvatarText(name) {
  const profileIconName = name
    .split(' ')
    .map((_name) => _name[0])
    .join('')
    .toUpperCase();

  return profileIconName;
}
export function computePayload(data, fragmentData, dataKey) {
  switch (data.computeFunction) {
    case 'default':
      return data.params[0];
    case 'pickValueFromScreenData':
      if (fragmentData?.[dataKey] === undefined) {
        return data.params[0];
      }
      return fragmentData?.[dataKey];
    case 'constructMacroCase':
      const key = data.params[0];
      return fragmentData?.[key].replaceAll(' ', '_').toUpperCase();
    case 'appendText':
      return data.params[0].text + fragmentData?.[data.params[0].key];

    case 'constructText':
      let parsedData = data.params[0];
      let formattedString = '';
      parsedData.forEach((item) => {
        let key = item.substring(item.indexOf('{') + 1, item.lastIndexOf('}'));
        if (key) {
          console.log(key);
          let keyParts = key.split('.');
          let customFieldValue = fragmentData?.customFields;
          keyParts.forEach((part) => {
            customFieldValue = customFieldValue?.[part];
          });
          let actualKey = '{' + key + '}';

          if (fragmentData?.contextVars[key]) {
            let formattedItem = item.replaceAll(actualKey, fragmentData?.contextVars[key]);
            formattedString = formattedString + formattedItem;
          } else if (customFieldValue) {
            let formattedItem = item.replaceAll(actualKey, customFieldValue);
            formattedString = formattedString + formattedItem;
          } else {
            let formattedItem = item.replaceAll(actualKey, '-');
            formattedString = formattedString + formattedItem;
          }
        } else {
          formattedString = formattedString + item;
        }
      });
      return formattedString.trim();

    case 'getCombinedSID':
      return `${data.params[0]}-${data.plugData}`;

    case 'getFromRowInstance':
      const nestedParams = data.params[0].split('.');
      if (nestedParams.length === 1) {
        return `${data.instance[nestedParams[0]]}`;
      } else {
        return `${data.instance[nestedParams[0]][nestedParams[1]]}`;
      }
    default:
      return data.params[0];
  }
}

export function constructURLQueryString(queryArr, instance) {
  if (!queryArr) return '';
  let queryParams = {};

  queryArr.forEach((query) => {
    if (typeof query.value === 'object') {
      queryParams[query.name] = computePayload({ ...query.value, instance });
    } else {
      queryParams[query.name] = query.value;
    }
  });
  const params = new URLSearchParams(queryParams);
  return params.toString();
}
export function getRandomInt(max) {
  return Math.floor(Math.random() * max);
}

export function transformFunction(data, bulkUploadData, entityData) {
  if (data.transformFunction === 'insertKeyValuePairInArrayOfObjects') {
    const referenceId = data.params[0].key;
    const entityId = data.params[0].value;
    if (data.params[1] === 'bulkUploadInstances') {
      const payload = bulkUploadData.map((obj) => {
        const newObj = { ...obj };
        newObj[referenceId] = entityData[entityId];
        return newObj;
      });
      console.log(payload);
      return payload;
    }
  }
}

export function constructPayLoadData(payload, instance) {
  let _dataPayload = {};

  _.forEach(payload, (value, key) => {
    if (typeof value === 'string') {
      _dataPayload[key] = formatPayloadString(value, instance);
    }
    if (typeof value === 'object') {
      if (value.hasOwnProperty('computeFunction')) {
        _dataPayload[key] = computePayload(value, instance);
      }
    }
  });
  return _dataPayload;
}

export function formatPayloadString(_value, instance) {
  let formattedString;
  let extractedValue = _value.split('.');
  if (extractedValue.length > 1) {
    let source = [];

    switch (extractedValue[0]) {
      case 'entityResponse':
        source = instance;
        break;

      default:
        source = _value;
    }

    if (source[extractedValue[1]]) {
      formattedString = source[extractedValue[1]];
    }
  }
  return formattedString;
}

const requiredFieldsSet = new Set();
export function handleEvent(
  fieldDefinition,
  fieldKey,
  fragmentData,
  setDisableButtons,
  dispatch,
  source,
  fieldMap,
  data,
) {
  const isRequired = data?.allDataRequired === true || fieldDefinition?.required;
  if (isRequired) {
    let fieldValue = '';
    if (source) {
      let fieldSource = fragmentData?.[source];
      fieldValue = fieldSource?.[fieldKey];
    } else {
      fieldValue = fragmentData?.[fieldKey];
    }
    const isFilled = !!fieldValue && fieldValue !== '';
    dispatch(setDisableButtons(!isFilled));
  } else {
    // setDisableButtons(false);
  }
}

export function getFieldValidation(fields, getField, setError, fragmentData, value) {
  fields?.forEach((fieldKey) => {
    const fieldDefinition = getField(fieldKey);
    const isRequired = fieldDefinition?.required;
    const fieldValue = fragmentData?.[fieldKey];
    const isFilled = !!fieldValue && fieldValue !== '';
    if (isRequired && value === '') {
      setError((prevError) => ({
        ...prevError,
        [fieldKey]: !isFilled,
      }));
    } else {
      setError((prevError) => ({
        ...prevError,
        [fieldKey]: false,
      }));
    }
  });
}
export function dateDisplay(current, fieldDefinition, selectedDate) {
  if (fieldDefinition?.allowPastDates === false && fieldDefinition?.allowFutureDates === true) {
    return selectedDate ? current.isAfter(selectedDate) : current.isAfter(yesterday);
  } else if (
    fieldDefinition?.allowPastDates === true &&
    fieldDefinition?.allowFutureDates === false
  ) {
    return selectedDate ? current.isBefore(selectedDate) : current.isBefore(today);
  } else {
    return true;
  }
}

export const iff = (condition, then, otherwise) => (condition ? then : otherwise);

export function transformDataArray(dataArray, keyMappings) {
  return dataArray.map((item) => {
    const transformedItem = { ...item };

    for (const key in keyMappings) {
      if (keyMappings.hasOwnProperty(key)) {
        const newKey = keyMappings[key];
        transformedItem[newKey] = transformedItem[key];
        delete transformedItem[key];
      }
    }

    if (transformedItem.children) {
      transformedItem.children = transformDataArray(transformedItem.children, keyMappings);
    }

    return transformedItem;
  });
}

export function extractVariableAndReplaceValues(pattern, data) {
  const replacedPattern = pattern?.replace(/\{\{(\w+)\}\}|\w+/g, (match, key) => {
    if (match?.startsWith('{{') && match?.endsWith('}}')) {
      const placeholder = match?.slice(2, -2);
      return data?.[placeholder] || match;
    } else {
      return data?.[match] || match;
    }
  });
  return replacedPattern;
}

const colors = [
  '#101B3E',
  '#800B88',
  '#467E09',
  '#0780AF',
  '#833912',
  '#26462D',
  '#572741',
  '#8E6428',
  '#3D4F6E',
  '#C53030',
  '#219653',
  '#11422E',
  '#EC4899',
  '#699D12',
  '#B94E48',
  '#176641',
  '#5E2572',
  '#D69E2E',
  '#496C8B',
  '#FF5E5E',
  '#38A169',
  '#F472B6',
  '#9CA3AF',
  '#8B5CF6',
  '#D97706',
  '#4F89CC',
  '#DB2777',
  '#FACC15',
  '#364E68',
];

export function generateColors(count: number): string[] {
  const result: string[] = [];

  for (let i = 0; i < count; i++) {
    result.push(colors[i % colors.length]);
  }

  return result;
}

export function constructTimelineLabels(layoutData, entityData, extensionEntityData) {
  let labelList: any = {};
  Object.keys(entityData.fields).map((key) => {
    if (layoutData?.fields && layoutData?.fields[key]?.title) {
      labelList[key] = layoutData.fields[key].title;
    } else {
      labelList[key] = entityData.fields[key].title;
    }
  });
  Object.keys(extensionEntityData.fields).map((key) => {
    if (layoutData?.fields && layoutData?.fields[key]?.title) {
      labelList[key] = layoutData.fields[key].title;
    } else {
      labelList[key] = extensionEntityData.fields[key].title;
    }
  });
  Object.keys(layoutData.fields).map((key) => {
    if (!labelList[key]) {
      labelList[key] = layoutData?.fields[key]?.title;
    }
  });
  return labelList;
}

export function replacePlaceholders(options: any, data: any) {
  function traverse(obj) {
    for (const key in obj) {
      if (typeof obj[key] === 'string') {
        if (obj[key].includes('$')) {
          const placeholderKey = obj[key].split('$')[1]?.split('.')[1];
          if (placeholderKey && data.hasOwnProperty(placeholderKey)) {
            obj[key] = data[placeholderKey];
          }
        }
      } else if (typeof obj[key] === 'object') {
        traverse(obj[key]);
      }
    }
  }

  const clonedOptions = JSON.parse(JSON.stringify(options)); // Deep clone the options object
  if (Object.keys(data)?.length) {
    traverse(clonedOptions);
  }

  return clonedOptions;
}
